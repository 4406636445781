<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';

  import axios from 'axios';

  import Swal from 'sweetalert2';

  import { validationMixin } from "vuelidate";
  import { email, required, maxLength, sameAs, requiredUnless } from "vuelidate/lib/validators";

  import AttachmentRow from './components/AttachmentRow';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'application',

    components: {
      AttachmentRow
    },

    props: [
      'firstApplication',
      'fullYearOpen'
    ],

    mixins: [validationMixin],

    validations: {
      name: {
        required,
        maxLength: maxLength(255)
      },

      email: {
        required,
        email
      },

      confirmEmail: {
        required,
        email,
        sameAsEmail: sameAs(function() {
          return this.email;
        })
      },

      identityNo: {
        maxLength: maxLength(13)
      },

      title: {
        maxLength: maxLength(255)
      },

      applicationManagementEntity: {
        maxLength: maxLength(255)
      },

      organisationInformation: {
        maxLength: maxLength(500)
      },

      postalAddress: {
        required,
        maxLength: maxLength(255)
      },

      phoneNo: {
        required,
        maxLength: maxLength(20)
      },

      employment: {
        maxLength: maxLength(255)
      },

      previousGrants: {
        maxLength: maxLength(500)
      },

      projectTitle: {
        required,
        maxLength: maxLength(255)
      },

      applicationAmount: {
        required: requiredUnless('fullYearApplication'),
        maxLength: maxLength(255)
      },

      projectDescription: {
        required,
        maxLength: maxLength(1500)
      },

      grantsFromOtherSources: {
        maxLength: maxLength(255)
      },

      termsAccepted: {
        sameAs: sameAs(() => true)
      }
    },

    created() {
      if (this.firstApplication != null) {
        console.log("This is a second step application: ", this.firstApplication);
        this.fullYearApplication = true;
        this.researchStipendSixMonths = false;
        this.researchStipendTwelveMonths = false;
        this.name = this.firstApplication.name;
        this.email = this.firstApplication.email;
        this.confirmEmail = this.firstApplication.email;
        this.identityNo = this.firstApplication.identity_no;
        this.title = this.firstApplication.title;
        this.applicationManagementEntity = this.firstApplication.application_management_entity;
        this.organisationInformation = this.firstApplication.organisation_information;
        this.postalAddress = this.firstApplication.postal_address;
        this.phoneNo = this.firstApplication.phone_no;
        this.employment = this.firstApplication.employment;
        this.applicationAmount = 350000;
        this.previousApplicationID = this.firstApplication.id;
      }
    },

    data() {
      return {
        fullYearApplication: false,
        researchStipendSixMonths: false,
        researchStipendTwelveMonths: false,
        name: null,
        email: null,
        confirmEmail: null,
        identityNo: null,
        title: null,
        applicationManagementEntity: null,
        organisationInformation: null,
        postalAddress: null,
        phoneNo: null,
        employment: null,
        previousGrants: null,
        projectTitle: null,
        applicationAmount: null,
        projectDescription: null,
        grantsFromOtherSources: null,
        previousApplicationID: null,

        attachments: [],

        termsAccepted: false,
        fullYearStipendAccepted: false,
        researchStipendAccepted: false,

        sending: false,

        showSubmitWarning: false
      }
    },

    watch: {
      fullYearApplication: function (state) {
        if (state == true) {
          this.researchStipendSixMonths = false;
          this.researchStipendTwelveMonths = false;
        }
      },

      researchStipendSixMonths: function (state) {
        if (state == true) {
          this.fullYearApplication = false;
          this.researchStipendTwelveMonths = false;
        }
      },

      researchStipendTwelveMonths: function (state) {
        if (state == true) {
          this.researchStipendSixMonths = false;
          this.fullYearApplication = false;
        }
      }
    },

    computed: {
      newAttachmentCount() {
        var newCount = 0;

        for (var i = 0; i < this.attachments.length; i++) {
          if (this.attachments[i].file != null) {
            newCount++;
          }
        }

        return newCount;
      }
    },

    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },

      createApplication() {
        var self = this;

        this.$v.$touch();

        if (this.$v.$invalid) {
          this.showSubmitWarning = true;
        }

        if (!this.$v.$invalid) {
          this.sending = true;
          this.showSubmitWarning = false;

          axios.post('/api/applications', {
            full_year_application: this.fullYearApplication,
            research_stipend_six_months: this.researchStipendSixMonths,
            research_stipend_twelve_months: this.researchStipendTwelveMonths,
            name: this.name,
            email: this.email,
            identity_no: this.identityNo,
            title: this.title,
            application_management_entity: this.applicationManagementEntity,
            organisation_information: this.organisationInformation,
            postal_address: this.postalAddress,
            phone_no: this.phoneNo,
            employment: this.employment,
            previous_grants: this.previousGrants,
            project_title: this.projectTitle,
            application_amount: this.applicationAmount,
            project_description: this.projectDescription,
            grants_from_other_sources: this.grantsFromOtherSources,
            previous_application_id: this.previousApplicationID
          })
          .then(function (response) {
            self.sending = false;

            if (self.attachments.length != 0) {
              if (self.newAttachmentCount > 0) {
                self.uploadFile(response.data.data.id);
              } else {
                Swal.fire({
                  icon: 'success',
                  title: 'Ansökan mottagen',
                  text: 'Vi återkommer när vi har behandlat din ansökan. En bekräftelse har skickats till din e-postadress: ' + self.email + '. Kontrollera skräpposten om du inte får den inom ett par timmar!',
                  onClose: () => {
                    self.fullYearApplication = false;
                    self.researchStipendSixMonths = false;
                    self.researchStipendTwelveMonths = false;
                    self.name = null;
                    self.email = null;
                    self.identityNo = null;
                    self.title = null;
                    self.applicationManagementEntity = null;
                    self.organisationInformation = null;
                    self.postalAddress = null;
                    self.phoneNo = null;
                    self.employment = null;
                    self.previousGrants = null;
                    self.projectTitle = null;
                    self.applicationAmount = null;
                    self.projectDescription = null;
                    self.grantsFromOtherSources = null;
                    self.attachments = [];
                    self.termsAccepted = false;

                    self.$v.$reset();

                    setTimeout(function() {
                      self.$v.$reset();
                    }, 100);
                  }
                });
              }
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Ansökan mottagen',
                text: 'Vi återkommer när vi har behandlat din ansökan. En bekräftelse har skickats till din e-postadress: ' + self.email + '. Kontrollera skräpposten om du inte får den inom ett par timmar!',
                onClose: () => {
                  self.fullYearApplication = false;
                  self.researchStipendSixMonths = false;
                    self.researchStipendTwelveMonths = false;
                  self.name = null;
                  self.email = null;
                  self.identityNo = null;
                  self.title = null;
                  self.applicationManagementEntity = null;
                  self.organisationInformation = null;
                  self.postalAddress = null;
                  self.phoneNo = null;
                  self.employment = null;
                  self.previousGrants = null;
                  self.projectTitle = null;
                  self.applicationAmount = null;
                  self.projectDescription = null;
                  self.grantsFromOtherSources = null;
                  self.attachments = [];
                  self.termsAccepted = false;

                  self.$v.$reset();

                  setTimeout(function() {
                    self.$v.$reset();
                  }, 100);
                }
              });
            }
          })
          .catch(function (error) {
            self.sending = false;

            console.log("Error creating application: ", error);
          });
        }
      },

      uploadFile(applicationID) {
        var self = this;

        let fileData = new FormData();
        fileData.append('application_id', applicationID);
        for (var i = 0; i < this.attachments.length; i++) {
          if (this.attachments[i].file != null) {
           fileData.append('attachment[' + i + ']', this.attachments[i].file)
          }
        }

        this.sending = true;

        axios.post('/api/application/attachments', fileData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          self.sending = false;

          if (response.data.success == false) {
            Swal.fire({
              icon: 'warning',
              title: 'Fel vid uppladdning av bilaga',
              text: 'Bilagan får max vara 25 Mb och måste vara i PDF-format.',
              confirmButtonColor: '#096CEE',
              showConfirmButton: true
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Ansökan mottagen',
              text: 'Vi återkommer när vi har behandlat din ansökan. En bekräftelse har skickats till din e-postadress: ' + self.email + '. Kolla skräpposten om du inte får den inom ett par timmar!',
              onClose: () => {
                self.fullYearApplication = false;
                self.researchStipendSixMonths = false;
                self.researchStipendTwelveMonths = false;
                self.name = null;
                self.email = null;
                self.identityNo = null;
                self.title = null;
                self.applicationManagementEntity = null;
                self.organisationInformation = null;
                self.postalAddress = null;
                self.phoneNo = null;
                self.employment = null;
                self.previousGrants = null;
                self.projectTitle = null;
                self.applicationAmount = null;
                self.projectDescription = null;
                self.grantsFromOtherSources = null;
                self.attachments = [];
                self.termsAccepted = false;

                self.$v.$reset();

                setTimeout(function() {
                  self.$v.$reset();
                }, 100);
              }
            });
          }
        })
        .catch(function (error) {
          self.sending = false;

          Swal.fire({
            icon: 'warning',
            title: 'Fel vid uppladdning av bilaga',
            text: 'Ett fel inträffade vid uppladdning av bilaga, försök igen!',
            confirmButtonColor: '#096CEE',
            showConfirmButton: true
          });
        });
      },

      addAttachment() {
        this.attachments.push({
          id: this.attachments.length + 1,
          file: null
        });
      },

      removeAttachment(attachmentID) {
        var removeIndex = -1;

        for (var i = 0; i < this.attachments.length; i++) {
          let attachment = this.attachments[i];

          if (attachment.id == attachmentID) {
            removeIndex = i;
          }
        }

        if (removeIndex != -1) {
          this.attachments.splice(removeIndex, 1);
        }
      },

      updateAttachment(attachmentID, attachment) {
        for (var i = 0; i < this.attachments.length; i++) {
          var currentAttachment = this.attachments[i];

          if (currentAttachment.id == attachmentID) {
            currentAttachment.file = attachment;
          }
        }
      }
    }
  };
</script>

<template>
  <div>
    <b-row>
      <b-col>
        <p><a href="https://salenstiftelsen.se" target="_blank">Gå till Salénstiftelsens hemsida</a></p>
        <h1>Ansökan</h1>
        <h3>Sven och Dagmar Saléns Stiftelse Sven och Dagmar Saléns Vetenskaps- och Kulturstiftelse</h3>
        <p>Ofullständig ansökan som inte uppfyller de formella krav som anges i blanketten eller på hemsidan kommer inte att behandlas.</p>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col>
        <b-form-checkbox class="" v-if="fullYearOpen" v-model="fullYearApplication">
          <h6 class="mt-1">Helårsansökan (Kryssas endast för ansökningar till helårsstipendier)</h6>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row class="my-1" v-if="fullYearApplication">
      <b-col>
        <b-form-checkbox v-model="fullYearStipendAccepted">
          Jag har tagit del av villkoren gällande helårsstipendier som beskrivs på <a href="https://salenstiftelsen.se/helarspremie.html" target="_blank" class="link-black">hemsidan</a>.
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- <b-row class="my-1 mt-4">
      <b-col>
        <b-form-checkbox class="" v-model="researchStipendSixMonths">
          <h6 class="mt-1">Ansökan avser Forskningsstipendie, 6 mån</h6>
        </b-form-checkbox>
      </b-col>
    </b-row> -->

    <!-- <b-row class="my-1">
      <b-col>
        <b-form-checkbox class="" v-model="researchStipendTwelveMonths">
          <h6 class="mt-1">Ansökan avser Forskningsstipendie, 12 mån</h6>
        </b-form-checkbox>
      </b-col>
    </b-row> -->

    <!-- <b-row class="my-1" v-if="researchStipendSixMonths || researchStipendTwelveMonths">
      <b-col>
        <b-form-checkbox v-model="researchStipendAccepted">
          Jag har tagit del av villkoren gällande forskningsstipendier som beskrivs på <a href="https://salenstiftelsen.se/forskningsstipendier.html" target="_blank" class="link-black">hemsidan</a>.
        </b-form-checkbox>
      </b-col>
    </b-row> -->

    <!-- <b-row class="my-1">
      <b-col>
        <h6>Avser ansökan Forskningsstipendium och att förutsättningarna kan anses uppfyllda, skall någon av de ovanstående två rutorna kryssas.</h6>
        <h6><strong>För alla övriga ansökningar skall sålunda rutorna lämnas blanka.</strong></h6>
      </b-col>
    </b-row> -->




    <b-row class="mt-4">
      <b-col md="6">
        <b-form-group label="Namn" description="Måste anges.">
          <b-form-input v-model="$v.name.$model" :state="validateState('name')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.name.required">Ett namn måste anges.</p>
            <p v-if="!$v.name.maxLength">Namnet får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="E-postadress" description="Måste anges.">
          <b-form-input v-model="$v.email.$model" :state="validateState('email')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.email.required">En e-postadress måste anges.</p>
            <p v-if="!$v.email.email">En korrekt e-postadress måste anges.</p>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Upprepa e-postadress" description="Måste anges.">
          <b-form-input v-model="$v.confirmEmail.$model" :state="validateState('confirmEmail')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.confirmEmail.required">En e-postadress måste anges.</p>
            <p v-if="!$v.confirmEmail.email">En korrekt e-postadress måste anges.</p>
            <p v-if="!$v.confirmEmail.sameAsEmail">De angivna e-postadresserna måste vara indentiska.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group label="Personnummer">
          <b-form-input v-model="$v.identityNo.$model" :state="validateState('identityNo')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.identityNo.maxLength">Personnumret får inte vara längre än 13 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Titel">
          <b-form-input v-model="$v.title.$model" :state="validateState('title')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.title.maxLength">Titlen får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group label="Anslagsförvaltande organ (om annan än sökanden)">
          <b-form-input v-model="$v.applicationManagementEntity.$model" :state="validateState('applicationManagementEntity')" :disabled="researchStipendSixMonths || researchStipendTwelveMonths" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.applicationManagementEntity.maxLength">Får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="6"></b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Om ansökan inges av juridisk person skall uppgifter lämnas om organisationen, dess syfte, finansiering, medlemskap och om vem som företräder den">
          <b-form-textarea v-model="$v.organisationInformation.$model" :state="validateState('organisationInformation')" rows="3"
      max-rows="3" trim></b-form-textarea>

          <b-form-invalid-feedback>
            <p v-if="!$v.organisationInformation.maxLength">Får inte vara längre än 500 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Postadress" description="Måste anges.">
          <b-form-input v-model="$v.postalAddress.$model" :state="validateState('postalAddress')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.postalAddress.required">Postadress måste anges.</p>
            <p v-if="!$v.postalAddress.maxLength">Postadressen får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
         <b-form-group label="Telefon dagtid" description="Måste anges.">
          <b-form-input v-model="$v.phoneNo.$model" :state="validateState('phoneNo')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.phoneNo.required">Telefonnummer måste anges.</p>
            <p v-if="!$v.phoneNo.maxLength">Telefonnumret får inte vara längre än 20 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Anställning">
          <b-form-input v-model="$v.employment.$model" :state="validateState('employment')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.employment.maxLength">Anställning får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Tidigare erhållna anslag från stiftelsen under de 5 senaste åren (ange årtal och belopp). Ange varje tillfälle på en egen rad">
          <b-form-textarea v-model="$v.previousGrants.$model" :state="validateState('previousGrants')" rows="5"
      max-rows="10" trim></b-form-textarea>

          <b-form-invalid-feedback>
            <p v-if="!$v.previousGrants.maxLength">Får inte vara längre än 500 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group label="Projekttitel" description="Måste anges.">
          <b-form-input v-model="$v.projectTitle.$model" :state="validateState('projectTitle')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.projectTitle.required">Projekttitel måste anges.</p>
            <p v-if="!$v.projectTitle.maxLength">Projekttitelen får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Sökt belopp (SEK)" :description="(fullYearApplication) ? '' : 'Måste anges.'">
          <b-form-input v-model="$v.applicationAmount.$model" :state="validateState('applicationAmount')" trim :disabled="fullYearApplication"></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.applicationAmount.required">Sökt belopp måste anges.</p>
            <p v-if="!$v.applicationAmount.maxLength">Sökt belopp får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
         <b-form-group label="Kort sammanfattning av ändamål/forskningsplan för vilket anslag söks" description="Måste anges.">
          <b-form-textarea v-model="$v.projectDescription.$model" :state="validateState('projectDescription')" rows="9"
      max-rows="9" trim></b-form-textarea>

          <b-form-invalid-feedback>
            <p v-if="!$v.projectDescription.required">Sammanfattning måste anges.</p>
            <p v-if="!$v.projectDescription.maxLength">Sammanfattningen inte vara längre än 1500 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Sökta/erhållna anslag från andra källor">
          <b-form-input v-model="$v.grantsFromOtherSources.$model" :state="validateState('grantsFromOtherSources')" trim></b-form-input>

          <b-form-invalid-feedback>
            <p v-if="!$v.grantsFromOtherSources.maxLength">Får inte vara längre än 255 tecken.</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <h4>Bilagor</h4>
        <ol v-if="!fullYearApplication && !(researchStipendSixMonths || researchStipendTwelveMonths)">
          <li>Kortfattad CV + meritförteckning, högst två sidor</li>
          <li>Sökande som önskar åberopa publicerade skrifter skall begränsa sig till 5 stycken</li>
          <li>Kortfattad forsknings-/projektplan, högst två sidor</li>
          <li>Finansieringsplan, 1 sida</li>
          <li>Intyg som åberopas. Doktorand skall bifoga handledarintyg. Högst fyra sidor</li>
          <li>Gäller ansökan täckande av omkostnader för deltagande i en konferens skall inbjudan bifogas där det framgår att projektet är accepterat för presentation</li>
          <li>Inga tryckta skrifter eller manuskript skall sändas med</li>
          <li>Gäller ansökan tryckningsbidrag skall innehållsförteckning samt offert från tryckeri eller förlag biläggas</li>
          <li>Sammantaget bör ansökan inte omfatta mer än 10 sidor</li>
        </ol>

        <p v-if="fullYearApplication">Ansökan skall innehålla följande (totalt högst 5 sidor inklusive ansökningsblanketten):</p>
        <ol v-if="fullYearApplication">
          <li>Personuppgifter, inkl. fullständig postadress, e-postadress och telefonnummer</li>
          <li>Uppgift om år för disputation</li>
          <li>Aktuell anknytning till lärosäte</li>
          <li>Uppgift om sökandes viktigaste vetenskapliga arbeten (högst 5)</li>
          <li>Kortfattad (högst 2 sidor) beskrivning av planerad forskning</li>
          <li>Uppgift om institution(er) som skall besökas</li>
          <li>Referenser till 3 personer</li>
        </ol>

        <ol v-if="researchStipendSixMonths || researchStipendTwelveMonths">
          <li>Kortfattad CV + meritförteckning, högst två sidor</li>
          <li>Sökande som önskar åberopa publicerade skrifter skall begränsa sig till 5 stycken</li>
          <li>Kortfattad forsknings-/projektplan, högst två sidor</li>
          <li>Intyg som åberopas. Högst fyra sidor</li>
          <li>Inga tryckta skrifter eller manuskript skall sändas med</li>
          <li>Sammantaget bör ansökan inte omfatta mer än 10 sidor</li>
        </ol>


        <p>OBS! Alla bilagor måste vara i PDF-format. Du kan lägga till upp till 10 bilagor.</p>
        <p>Klicka på knappen Lägg till bilaga för varje bilaga du vill lägga till och ladda därefter upp bilagorna en och en på respektive rad.</p>

        <b-button @click="addAttachment" variant="primary" :disabled="this.attachments.length >= 10" class="mb-2">Lägg till bilaga</b-button>

        <attachment-row
          v-for="(attachment, index) in attachments"
          :attachment="attachment"
          :key="attachment.id"
          :row-no="index"
          @removeAttachment="removeAttachment"
          @updateAttachment="updateAttachment"
        ></attachment-row>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-form-checkbox v-model="$v.termsAccepted.$model" :state="validateState('termsAccepted')">
          Jag samtycker till Sven och Dagmar Saléns Stiftelsers <a href="http://salenstiftelsen.se/policy-for-integritet-och-dataskydd/" target="_blank" class="link-black">integritetspolicy</a>.
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-button @click="createApplication" variant="primary" class="my-4" :disabled="sending">Skicka ansökan</b-button>
    <p class="text-danger" v-if="showSubmitWarning">Kontrollera att alla fält är korrekt ifyllda och försök igen.</p>
  </div>
</template>
